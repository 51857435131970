import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => (
	<div>
		<Helmet>
			<meta name="robots" content="noindex" />
		</Helmet>

		<h1>404: Not Found</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	</div>
);

export default NotFoundPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
